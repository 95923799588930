"use strict";
var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.renderer = exports.getCleaner = void 0;
require("../assets/main.scss");
require("../abTest/variants.scss");
const template_1 = require("./template");
const getCleaner = (htmlElement) => (handler) => {
    return () => {
        htmlElement.remove();
        handler();
    };
};
exports.getCleaner = getCleaner;
const getOptions = () => {
    const toolbar = document.getElementById('toolbar');
    return toolbar
        ? {
            wrapper: toolbar,
            className: 'cookie-disclaimer__holder--toolbar',
        }
        : {
            wrapper: document.body,
            className: 'cookie-disclaimer__holder',
        };
};
const renderer = (content, handlers, tracking, abTestContext) => __awaiter(void 0, void 0, void 0, function* () {
    const { wrapper, className } = getOptions();
    const banner = document.createElement('div');
    const cleaner = (0, exports.getCleaner)(banner);
    const html = (0, template_1.template)(content);
    banner.classList.add(className, ...abTestContext.toolbarCssClasses);
    banner.innerHTML = html;
    banner.querySelector('#cookieBannerManage').addEventListener('click', cleaner(() => handlers.manage()));
    banner.querySelector('#cookieBannerAccept').addEventListener('click', cleaner(() => handlers.accept()));
    banner.querySelector('#cookieBannerAcceptEssential').addEventListener('click', cleaner(() => handlers.acceptEssential()));
    wrapper.insertBefore(banner, wrapper.firstChild);
    tracking.displayed();
});
exports.renderer = renderer;
